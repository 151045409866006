.navigation {
  position: absolute;
  width: 100%;
  z-index: 9999;
}
.header-top {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.header-top .header-top-socials {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
.header-top .header-top-socials a {
  margin-left: 20px;
  /* color: black; */
  font-size: 14px;
}
.header-top .header-top-info a {
  /* color: black; */
  padding-right: 35px;
  font-size: 14px;
}
.header-top .header-top-info a:first-child {
  padding-left: 0px;
}
.headerMenu ul {
  list-style: none;
  display: flex;
}
.headerMenu ul li {
  flex: 1;
  text-align: right;
}

.topbg {
  background: url("../../images/bg/slider-1.jpg") no-repeat;
  background-size: cover;

  position: relative;
}
.tp {
  background: rgba(23, 24, 33, 0.75);
  color: white;
}
.topbg a {
  color: white;
}
.headerMenu button {
  position: absolute;
  width: 15%;
  margin-top: -10px;
  margin-left: -4vw;
}
.headerpage {
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}
.headerpage a {
  color: black;
}
.headerpage .row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
}
