.quotepage {
  padding: 30px;
  /* background: white; */
  width: 50%;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: black;
  box-shadow: 1px 1px 40px 4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 40px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 40px 4px rgba(0, 0, 0, 0.75);
}
.quotepage input,
select {
  padding: 5px;
  width: 95%;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
  height: 45px;
}
.quotepage textarea {
  padding: 5px;
  width: 95%;
  margin-bottom: 20px;
  height: 20vh;
}
.quotepage label {
  font-weight: bold;
}
.flimg {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}
.flimg img {
  margin-top: 10vh;
}

@media only screen and (max-width: 800px) {
  .quotepage {
    width: 100%;
    margin: auto;
  }
}
