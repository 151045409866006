.partnermain {
  display: flex;
  width: 30%;
  margin: auto;
}
.partnermain img {
  height: 60px;
  flex: 1;
}
.partner h2 {
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
  margin: 40px;
  font-weight: bold;
  color: brown;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#eee, brown);
  -webkit-background-clip: text;
  font-size: 40px;
}
