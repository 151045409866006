.about {
  margin: 10vh;
}
.about h2 {
  text-transform: capitalize;
  text-align: center;
  margin: 40px;
  font-weight: bold;
  color: brown;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#eee, brown);
  -webkit-background-clip: text;
  font-size: 40px;
}
._about_head {
  font-size: 14px;
}
.about h6 {
  font-weight: bold;
}
.ionside {
  display: flex;

  justify-content: center;
  align-items: center;
}
.ionside ion-icon {
  font-size: 40px;
  border: 1px dotted black;
  padding: 10px;
  border-radius: 50%;
  color: brown;
}
.compside {
  display: flex;
  justify-content: center;
  align-items: center;
}
