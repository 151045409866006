.sbc {
  background-color: brown;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.sbc .row .col:nth-child(1) {
  font-size: 30px;
}
.sbc input {
  height: 35px;
  border: none;
  width: 60%;
  text-align: center;
}
.sbc button {
  height: 37px;
  border: 2px solid grey;
  background-color: grey;
  color: white;
}
