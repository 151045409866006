@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
html {
  overflow-x: hidden;
}
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.166667px;
  line-height: 1.7em;
  color: #7e8690;
  font-weight: 400;
  /* background: repeating-linear-gradient(
    to right,
    #f2f4f5,
    #dee3e4 2px,
    #dee3e4 2px,
    #dee3e4 200px
  ); */
  height: 100vh;
  /* background-color: black; */
  font-size: 13px;
  font-family: "Raleway", sans-serif;
}
a {
  text-decoration: none;
  /* color: white; */
}
button {
  height: 50px;
  font-weight: bold;
}
.flowhatsap {
  position: fixed;
  height: 60px;
  bottom: 10vh;
}
