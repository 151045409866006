.service-cards {
  position: relative;
  height: 80vh;
}
.service-cards p {
  font-size: 18px;
  font-weight: bold;
}
.cards {
  float: left;
  width: 25%;
  display: block;
  margin-right: 3%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.cards .insidetab {
  width: 90%;
  margin: auto;
  height: 100%;
  /* background-color: green; */
  /* height: 20vh; */
  background-color: aliceblue;
  padding: 20px;
  padding-top: 30%;
  padding-bottom: 30%;
  border-radius: 20px;
}
.service-cards h2 {
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
  margin: 40px;
  font-weight: bold;
  color: brown;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#eee, brown);
  -webkit-background-clip: text;
  font-size: 40px;
}
.service-cards label {
  font-weight: bold;
  font-size: 15px;
}
.DetailServices {
  padding-top: 5%;
  /* position: absolute; */
  width: 80%;
  /* background-color: green; */
  margin-left: 10%;
}
.insidetab ion-icon {
  font-size: 70px;
  color: brown;
  font-weight: bold;
}
.insidetab:hover {
  background-color: brown;
  color: white;
}
.insidetab:hover ion-icon {
  background-color: brown;
  color: white;
}
